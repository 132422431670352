export const optionalRightsConfig = {
  headings: [
    {
      headingLabel: "Item No.",
      inputType: "label",
      icon: "",
      border: true,
      width: "7%",
      selectOptions: [],
    },
    {
      headingLabel: "Description",
      inputType: "text",
      border: true,
      width: "30%",
    },
    {
      headingLabel: "Qty",
      inputType: "number",
      border: true,
      width: "8%",
      selectOptions: [],
    },
    {
      headingLabel: "Unit Cost",
      inputType: "number",
      border: true,
      width: "9%",
    },
    {
      headingLabel: "Sub Total",
      inputType: "label",
      border: true,
      width: "9%",
    },
    {
      headingLabel: "Currency",
      inputType: "select",
      border: true,
      width: "7%",
      selectOptions: [],
      lookupCurrencySelectOptions:[],
    },
    // {
    //   headingLabel: "Exchange Rate",
    //   inputType: "number",
    //   border: true,
    //   width: "10%",
    //   selectOptions: [],
    // },
    {
      headingLabel: "Total Cost",
      inputType: "label",
      border: true,
      width: "10%",
    },
    {
      headingLabel: "Revised?",
      inputType: "select",
      border: true,
      width: "7%",
      selectOptions: [],
    },
  ],
  dataNodes: [
    // "gl_number",
    // "setunit_id",
    // "chartofaccount_id",
    "item_no",
    "description",
    "quantity",
    "unit_cost",
    "subtotal_value",
    "currency_code",
    // "adj_curr_exch_rate",
    "total_cost_value",
    "is_revised",
  ],
  primaryKey: ["po_line_id"],
  isHboTenant:["HBO"],
  nodeFields: [
    {
      column: "info",
    },
  ],
  inlineEdits: [
    {
      dataNode: "item_no",
      width: "6%"
    },
    {
      dataNode: "description",
      width: "30%",
      placeholder: "Description",
    },
    {
      dataNode: "quantity",
      width: "7%",
      validateMethod: "validateNumberFloatOnly",
      placeholder: "Qty",
      // isMandatory: true,
    },
    {
      dataNode: "unit_cost",
      width: "9%",
      placeholder: "Unit Cost",
      validateMethod: "validateNumberFloatOnly",
      // isMandatory: true,
    },
    {
      dataNode: "subtotal_value",
      width: "7%",
    },
    {
      dataNode: "currency_code",
      width: "7%",
      placeholder: "Currency",
    },
    // {
    //   dataNode: "adj_curr_exch_rate",
    //   width: "10%",
    //   placeholder: "Exchange Rate",
    // },
    {
      dataNode: "total_cost_value",
      width: "9%",
    },
    {
      dataNode: "is_revised",
      width: "7%",
    },
    ,
  ],
  showActionsItemBottom:[
    {
    dataNode: "po_line_id",
    width: "7%",
  },
  {
    dataNode: "description",
    width: "25%",
  },
  {
    dataNode: "quantity",
    width: "7%",
  },
  {
    dataNode: "unit_cost",
    width: "8%",
  },
  {
    dataNode: "subtotal_value",
    width: "10%",
  },
  {
    dataNode: "adj_curr_exch_rate",
    width: "0%",
  },
  {
    dataNode: "currency_code",
    width: "4%",
  },
  
  
  {
    dataNode: "actual_cost",
    width: "18%",
    inputType: "number",
    fieldWidth:"50%"
  },
  {
    dataNode: "is_revised",
    width: "3%",
  }],
  actions: ["copy", "file", "info-circle", "pen", "trash-alt"], 
  isClearFields: true,
}; 
export const optionalRightsList = [

];

export const NewPOHeader = {
  po_id: null,
  po_number: null,
  po_prefix: null,
  vendor_id: null,
  vendor_location_id: null,
  notes: null,
  show_season_id: null,
  is_void: 0,
  prod_company_id: null,
  episode_id: null,
};

export const NewPOLine = [
  {
    po_line_id: null,
    po_id: null,
    episode_id: null,
    //gl_number: null,
    // setunit_id: null,
    // set_unit: null,
    // chartofaccount_id: null,
    acc_detail: null,
    description: null,
    quantity: null,
    unit_cost: null,
    subtotal: null,
    total_cost: null,
    currency_code: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    updated_by: null,
    notes: null,
    change_log: [],
  },
];

export const revised = [
  { label: "Y", value: "Y" },
  { label: "N", value: "N" },
];
