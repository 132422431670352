import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import BasicTabs from "../../../components/SharedComponents/BasicTabs/BasicTabs";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import ChartOfAccount from "../ChartOfAccounts/ChartOfAccount";
import ProductionCompanies from "../ProductionCompanies/ProductionCompanies";
import "./AdminSetupDashboard.scss";
import SetUnit from "../SetUnits/SetUnit";
import Vendors from "../Vendors/Vendors";
import { AppService } from "../../../services/AppService";
import Shows from "../Shows/Shows";
import Currencies from "../Currencies/Currencies";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import Modal from "../../../components/SharedComponents/Modal/Modal";
import Seasons from "../Seasons/Seasons";
import Versions from "../Versions/Versions";
import Task from "../Task/Task";
import Organization from "../Organisation/Organisation";
import Expenditure from "../Expenditure/Expenditure";
import Format from "../Format/Format";
import Language from "../Language/Language";
import MessageModal from "../../../../src/components/SharedComponents/MessageModal";
import * as appConstants from "../../../constants/appConstants";
import { adminMenuNonHBOTab,adminMenuHBOTab,vendorAdminMenuHBOTab} from "../../../header/Config";

class AdminSetupDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInitiated: false,
      selectedTabValue: props?.userContext?.activeTab,
      adminSetupTabs: null,
      criteriaDetails: null,
      operator: null,
      importGLsModal: false,
      hasUnsavedChanges: false,
      defaultTab:0
    };
    // this.adminDropdown$ = AppService.getCurrentAdminScreen().subscribe(
    //   (data) => {
    //     this.setState({ selectedTabValue: data });
    //   }
    // );
  }

  adminMenuPermission(){
    this.setState({selectedTabValue: this.props?.userContext?.activeTab ?? adminMenuNonHBOTab[0].tab_id});
    return adminMenuNonHBOTab;
  }
  adminHBOMenuPermission(){
    const vendorPermissionExists = this.props?.userContext?.user_profile?.user_roles[0]?.permissions?.some(
      (permission) => (permission.permission_name).toUpperCase() === appConstants?.LABEL_VENDOR_MANAGE_LOOKUP
    );
    if(vendorPermissionExists){
      this.setState({selectedTabValue: vendorAdminMenuHBOTab[0].tab_id});
      return vendorAdminMenuHBOTab;
    }else{
      this.setState({selectedTabValue: this.props?.userContext?.activeTab ?? adminMenuHBOTab[0].tab_id});
      return  adminMenuHBOTab;
    }
  }


  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };
  handleTabChange = (e, tabId) => {
    this.setState({ selectedTabValue: tabId });
  };
  componentDidMount() {
    this.setState({ adminSetupTabs: this.props?.userContext?.active_tenant?.tenant_id == 2 ? this.adminHBOMenuPermission() : this.adminMenuPermission() })
    this.checkManageUser();
    if (this.props?.location?.state) {
      this.handleTabChange("", parseInt(this.props?.location?.state, 10));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.userContext?.active_tenant?.tenant_id !==
      prevProps?.userContext?.active_tenant?.tenant_id
    ) {
      this.checkManageUser();
    }
    if (
      this.props?.userContext?.activeTab !==
      prevProps?.userContext?.activeTab
    ) {
        this.setState({
            selectedTabValue: this.props?.userContext?.activeTab
        });
    }
  }
  componentWillUnmount() {}
  checkManageUser = () => {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    if (userPermissions?.isManageUser) {
      this.props.history.push("/usermanagement");
    }
  };
  importGLsClick = (id, dataItem) => {
    this.setState({
      importGLsModal: !this.state.importGLsModal,
    });
  };

  showModal = (event) => {
    this.setState({ hasUnsavedChanges: true });
  };

  checkVendorPermission(){
    const vendorPermissionExists = this.props?.userContext?.user_profile?.user_roles[0]?.permissions?.some(
      (permission) => (permission.permission_name).toUpperCase() === appConstants?.LABEL_VENDOR_MANAGE_LOOKUP
    );
    return vendorPermissionExists;
  }

  render() {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    return (
      <>
        <div>
          {userPermissions?.isAdmin ||((userPermissions?.isRegular && this.checkVendorPermission())) ? (
            <div>
              {this.state.importGLsModal && (
                <Modal
                  open={this.state.importGLsModal || false}
                  modalName={"ImportGL"}
                  comfirmModalTitle={"Import GL's"}
                  onClose={this.importGLsClick}
                />
              )}
              <MDBContainer className="admin-setup">
                <MDBRow>
                  <MDBCol md={6} className="heading">
                    <h3>Admin Setup</h3>
                  </MDBCol>
                  <MDBCol md={6} className="admin-msg">                   
                    <span>
                    {this.props?.userContext?.active_tenant?.tenant_id === 2 ?
                      "Please contact the application support team if you wish to delete a lookup value which is in use.":"Please contact the application support team if you wish to delete a lookup value"}
                    </span>
  
                  </MDBCol>
                </MDBRow>
                <MDBRow className="admin-setup-tab">
                  <MDBCol md={11} className="tabs">
                    <BasicTabs
                      value={this.state?.selectedTabValue}
                      variant={"scrollable"}
                      scrollButtons={"auto"}
                      tabList={this.state.adminSetupTabs?.map((item) => ({
                        value: item.tab_id,
                        label: item.tab_name,
                      }))}
                      onChange={(e, tabId) => {
                        this.handleTabChange(e, tabId);
                      }}
                    />
                  </MDBCol>
                  {this.state.selectedTabValue === 5 && this.props.userContext?.active_tenant?.tenant_id!=2 &&(
                    <MDBCol md={1} className="importButton">
                      <BasicButton
                        className="import-gl-btn"
                        text="Import GL's"
                        icon={"upload"}
                        onClick={this.importGLsClick}
                      />
                    </MDBCol>
                  )}
                </MDBRow>
                {this.state.selectedTabValue === 1 && (
                  <>
                    <MDBRow>
                      <ChartOfAccount showModal={this.showModal} />
                    </MDBRow>
                  </>
                )}
                {this.state.selectedTabValue === 2 && (
                  <MDBRow>
                    <MDBRow>
                      <ProductionCompanies />
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 3 && (
                  <MDBRow>
                    <MDBRow>
                      <SetUnit></SetUnit>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 4 && (
                  <MDBRow>
                    <MDBRow>
                      <Vendors></Vendors>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 5 && (
                  <MDBRow>
                    <MDBRow>
                      <Shows {...this.props}></Shows>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 6 && (
                  <MDBRow>
                    <MDBRow>
                      <Currencies></Currencies>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 7 && (
                  <MDBRow>
                    <MDBRow>
                      <Seasons
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Seasons>
                    </MDBRow>
                  </MDBRow>
                )}
                 {this.state.selectedTabValue === 8 && (
                  <MDBRow>
                    <MDBRow>
                      <Versions
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Versions>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 9 && (
                  <MDBRow>
                    <MDBRow>
                      <Expenditure
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Expenditure>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 10 && (
                  <MDBRow>
                    <MDBRow>
                      <Organization
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Organization>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 11 && (
                  <MDBRow>
                    <MDBRow>
                      <Task
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Task>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 12 && (
                  <MDBRow>
                    <MDBRow>
                      <Format
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Format>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.selectedTabValue === 13 && (
                  <MDBRow>
                    <MDBRow>
                      <Language
                        hasUnsavedChanges={this.state.hasUnsavedChanges}
                      ></Language>
                    </MDBRow>
                  </MDBRow>
                )}
                {this.state.haveUnsavedChanges && (
                  <MessageModal
                    open={this.state.isMessageModalOpen}
                    showError={this.state.haveUnsavedChanges || false}
                    primaryButtonText={"Ok"}
                    secondaryButtonText={"Cancel"}
                    hideCancel={false}
                    onConfirm={() => this.onModalConfirm()}
                    handleClose={(e) =>
                      this.setState({ isMessageModalOpen: false })
                    }
                  />
                )}
              </MDBContainer>
            </div>
          ) : (
            <div>You do not have permissions to this screen</div>
          )}
        </div>
      </>
    );
  }
}

export default withUserContext(AdminSetupDashboard);
