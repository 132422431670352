import React from "react";
import TableComponent from "../../components/SharedComponents/Table";
import _ from "lodash";
import { Card, Button } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { MDBBtn } from "mdb-react-ui-kit";
import "../Dashboard/DashboardPage.scss";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import IconTextField from "../../components/SharedComponents/IconTextField/IconTextField";
import DateField from "../../components/SharedComponents/DateField/DateField";
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import BasicCheckbox from "../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import {
  handlePaginationList,
  applyFilterTable,
  updateTableSortConfigObject,
  tableSortList,
} from "../../../src/Common/TableHelper";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
} from "../../../src/Common/Helper";
import TablePagination from "@material-ui/core/TablePagination";
import * as Constants from "../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from "../../contexts/UserContext";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import PostWatchService from "../../../src/services/service";
import {
  initialDashboardDropdownDetails,
  ActiveOrInActive,
  configObjectAllPoUsers,
  allUsersList,
  postFilters,
  chipFilterObject,
} from "./Config";
import { AppService } from "../../services/AppService";
import Chip from "@material-ui/core/Chip";
import axios from "axios";

export default withUserContext(
  class DashboardPage extends React.Component {
    constructor(props) {
      super(props);
      this.shows$ = null;
      this.state = {
        userPostJson: JSON.parse(
          JSON.stringify(initialDashboardDropdownDetails)
        ),
        postInitiated: false,
        shows: props?.userContext?.user_profile?.user_shows || [],
        episodes: [],
        seasons: [],
        seasonsList: [],
        vendors: [],
        isFetchingShows: false,
        isFetchingSeason: false,
        isFetchingEpisode: false,
        allData: [],
        listBackUp: [],
        tableList: [],
        tableListSorted: [],
        rowsPerPage: 10,
        totalRows: 0,
        newUserModal: false,
        isFetchingUserDetails: false,
        mode: null,
        userId: null,
        userDetails: null,
        totalCount: 0,
        searchTerm: null,
        is_enable: false,
        statusOptions: ActiveOrInActive,
        config: JSON.parse(JSON.stringify({ ...configObjectAllPoUsers })),
        isFetchingUserRoles: false,
        isFetchingTenants: false,
        userRoles: null,
        userRolesFilterOptions: null,
        showsFilterOptions: null,
        seasonFilterOptions: null,
        productionCompanyFilterOptions: null,
        vendorFilterOptions: null,
        tenants: null,
        selectedSeason: null,
        selectedEpisode: null,
        selectedShow: null,
        filters: { ...postFilters },
        chipFilterObject: { ...chipFilterObject },
        selectedFilterVendor: null,
        filterShowName:null,
        sortBy: null,
        sortOrder: "asc",
        a_z: false,
        selectedSeasonAdmin: null,
        selectedShowAdmin: null,
        selectedEpisodeAdmin: null,
        showsListAmdin: null,
        episodesListAmdin: null,
        userList:null,
        selectedFilterUser:null
      };
    }
    componentDidMount() { 
      this.removeConfigForNewTenant();
      this.checkManageUser();
      this.getPoDetails();
      this.props?.userContext?.active_tenant?.tenant_id !== 2 &&this.getProductionCompany();
      this.getSeasonsList();
      this.getFormat();
      this.getLanguage();
      // Clear all filters on page reload from navigation or back button
      this.setState({
        chipFilterObject: JSON.parse(JSON.stringify(chipFilterObject)),
        selectedFilterVendor: null,
        filterShowName:null,
        selectedFilterUser:null,
        filters: JSON.parse(JSON.stringify(postFilters)),
      });
      // To get newly created shows when they are created in shows page
      this.props?.userContext.getUserDetails();
    }

    removeConfigForNewTenant(){
      if(this.props?.userContext?.active_tenant?.tenant_id == 2){
        const updatedHeadingsConfig = configObjectAllPoUsers?.headings.filter(item=>item.dataNode !== 'comp_name');
        const updatedDataNodeConfig = configObjectAllPoUsers?.dataNodes.filter(item=>item !== 'comp_name');
        let configData = {...this.state.config}
        configData.headings = updatedHeadingsConfig;
        configData.dataNodes = updatedDataNodeConfig;
        this.setState({config: configData});

      }else if(this.props?.userContext?.active_tenant?.tenant_id == 1){
        const updatedHeadingsConfig = configObjectAllPoUsers?.headings.filter(item=>item.dataNode !== 'title');
        const updatedDataNodeConfig = configObjectAllPoUsers?.dataNodes.filter(item=>item !== 'title');
        let configData = {...this.state.config}
        configData.headings = updatedHeadingsConfig;
        configData.dataNodes = updatedDataNodeConfig;
        this.setState({config: configData});
      }

      
      
      return
    }

    checkManageUser = () => {
      let userPermissions = this.props?.userContext?.active_tenant?.permissions;
      if (userPermissions?.isManageUser) {
        this.props.history.push("/usermanagement");
      }
    };
    componentDidUpdate(prevProps, prevState) {
      console.log('didupdate',this.props?.userContext?.active_tenant);
      if (
        this.props?.userContext?.active_tenant?.tenant_id !==
        prevProps?.userContext?.active_tenant?.tenant_id   || this.props?.userContext?.active_tenant?.permissions?.isManageUser
      ) {
        this.checkManageUser();
      }
    }
    handleOnChange = (field, value) => {
      this.setState((prevState) => ({
        userPostJson: {
          ...prevState.userPostJson,
          [field]: value,
        },
      }));
    };
    handleFilterChange = (field, value, callFlag = true, flag = false) => {
      if (field === 'po_prefix') {
       value = value?.replace(/[a-z](?=\d)/gi, '$& ');
      }
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [field]: value,
            page_no: 1,
          },
        }),
        () => {
          if (callFlag) {
            this.getPoDetails();
            let chipFilterObject = { ...this.state.chipFilterObject };
            chipFilterObject[field].value = value;
            this.setState({
              chipFilterObject,
            });
          }
          if (flag) {
            this.getPoDetails();
          }
        }
      );
    };

    onKeyUpChange = (filter, value) => {
      let chipFilterObject = { ...this.state.chipFilterObject };
      chipFilterObject[filter].value = value;
      this.setState({
        chipFilterObject,
      });
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [filter]: value,
            page_no: 1,
          },
        }),
        () => {
          this.getPoDetails();
        }
      );
    };

    handleOnShowChange = (value) => {
      this.setState({
        selectedShow: value,
        selectedSeason: null,
        seasons: null,
        selectedEpisode: null,
        episodes: null
      });
      this.getSeason(value);
    };
    handleOnSeasonChange = (value) => {
      this.setState({
        selectedSeason: value,
        selectedEpisode: null,
        episodes: null
      });
      this.getEpisodes(value);
    };
    handleOnEpisodeChange = (value) => {
      this.setState({
        selectedEpisode: value,
      });
    };

    getSeasonsList = () => {
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/getseasons",
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            seasonsList: response.data,
          });
        },
        (err) => {
          console.log("error in get seasons");
        }
      );
    };

    getFormat=()=>{
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl +
        '/getlookupdata?objectType=format',
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            versionFormatList: response.data?.results,
          });
        },
        (err) => { }
      );
    }
  
    getLanguage=()=>{
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl +
        '/getlookupdata?objectType=language',
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            versionLanguageList: response.data?.results,
          });
        },
        (err) => { }
      );
    }

    getSeason = (showId) => {
      this.setState({ isFetchingSeason: true });
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/getshowepisodes?showId=" + showId,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            seasons: response.data[0].show_season,
            isFetchingSeason: false,
          });
        },
        (err) => {}
      );
    };

    getProductionCompany = () => {
      let filters = { comp_name: null, page_no: null, page_count: null };
      PostWatchService.getDataWithFilters(
        Constants.postWatchServiceBaseUrl + "/getproductioncompanies",
        filters,
        this.props?.userContext?.active_tenant?.tenant_id 
      )
        .then((res) => {
          let productionCompanyDetails = res.data?.records;
          this.setState({
            productionCompanyFilterOptions: res.data?.records,
            isLoading: false,
            isSubmit: false,
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.setState({ isLoading: false, isSubmit: false });
        });
    };
    getVendor = () => {
      this.setState({ isFetchingShows: true });
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/getvendors",
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          let filterObj = response.data?.map((item) => {
            return { value: item.vendor_id, label: item.vendor_name };
          });
          this.setState({
            vendorFilterOptions: filterObj,
            isFetchingShows: false,
          });
        },
        (err) => {}
      );
    };
    getEpisodes = (seasonId) => {
      let season = _.find(this.state.seasons, { season_id: seasonId });
      if (season) {
        this.setState({ episodes: season?.episodes });
      }
    };

    getPoDetails = () => {
      let filters = { ...this.state.filters };
      filters.page_count = this.state.rowsPerPage;
      filters.sort_by = this.state.sortBy;
      filters.sort_order = this.state.sortOrder;
      this.setState({
        isFetchingPODetails: true,
        filters,
      });
      PostWatchService.getDataWithMultiFilters(
        Constants.postWatchServiceBaseUrl +
          "/getalluserpos?userId=" +
          this.props?.userContext?.user_profile?.user_id,
        filters,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
              this.setState({
            tableListSorted: response.data.records,
            isFetchingPODetails: false,
            totalRows: response.data?.total_records_count,
          });
        },
        (err) => {
          console.log("Error in fetching set Details:", err);
          this.setState({
            isFetchingPODetails: false,
          });
        }
      );
    };

    handleEditUser = (val, item) => {
      this.setState({
        mode: "edit",
        userDetails: item,
        newUserModal: true,
      });
    };

    handleUserSelection = (item, node) => {
      if (node === "po_number" || node === "show_name") {
        this.props.history.push({
          pathname: "/showdetails",
          state: {
            po_id: item?.po_id ,
            season_id: item?.season_id,
            episode: -1,
            show_id: item?.show_id,

          },
        });   
        // remove OR condition data values and replace null check previous version code   
      }
    };

    handleUserDetailsUpdate = () => {
      this.setState({ page: 1, rowsPerPage: 10 });
      this.getAllUsers();
    };

    applyFilterTable = (config, data, filterValues, filtersNone) => {
      var finalResult = data.slice();
      if (!filtersNone) {
        filterValues.map((filter) => {
          var keyValue = filter.split(":");
          var name = keyValue[0].trim();
          var value = keyValue[1].trim().toLowerCase();

          var dataNode = null;

          config?.filters.map((item) => {
            if (item?.headingLabel === name) {
              dataNode = item.dataNode;
              return dataNode;
            }
          });

          // var nodeName = config?.dataNodes[index];
          var filteredList = null;

          if (dataNode === "user_shows") {
            filteredList = _.filter(finalResult, function (item) {
              return _.some(item.user_shows, {
                show_name: keyValue[1].trim(),
              });
            });
          } else {
            filteredList = finalResult?.filter((item) =>
              item[dataNode] != null
                ? item[dataNode].toString().toLowerCase().indexOf(value) > -1
                : false
            );
          }
          // finalResult = finalResult.concat(filteredList?.filter((list) => !finalResult.find(p => p[primaryKey] == list[primaryKey])));
          finalResult = filteredList.slice();
        });
      } else {
        finalResult = data;
      }
      //this.setState({filteredList : finalResult});
      return finalResult;
    };

    applyFilterUsersGridList = (filterValues, filtersNone) => {
      var finalResult = this.applyFilterTable(
        this.state.config,
        this.state?.listBackUp,
        filterValues,
        filtersNone,
        this.state.config.filters
      );
      this.setState({
        page: 0,
        totalRows: finalResult,
        tableListSorted: handlePaginationList(
          0,
          this.state.rowsPerPage,
          finalResult
        ),
      });
    };

    clearFilterHandler = () => {
      let chipFilterObject = { ...this.state.chipFilterObject };
      Object.keys(chipFilterObject).map((key, index) => {
        return (chipFilterObject[key].value = null);
      });

      this.setState(
        {
          chipFilterObject,
          filters: { ...postFilters },
          selectedFilterVendor: null,
          filterShowName:null,
          selectedFilterUser:null
        },
        () => {
          this.getPoDetails();
        }
      );
    };

    handleChangePage = (event, newPage) => {
      let filters = { ...this.state.filters };
      filters.page_no = newPage + 1;
      this.setState({ filters }, () => {
        this.getPoDetails();
      });
    };

    handleChangeRowsPerPage = (event) => {
      let filters = { ...this.state.filters };
      filters.page_no = 1;
      this.setState(
        {
          rowsPerPage: event.target.value,
          page: 1,
          filters,
        },
        () => {
          this.getPoDetails();
        }
      );
    };

    handleChipDelete = (pro, item) => {
      let chipFilterObject = { ...this.state.chipFilterObject };
      chipFilterObject[pro].value = null;
      this.handleFilterChange(pro, null);
      this.setState({ chipFilterObject });
      if (pro === "vendor_name") {
        this.setState({ selectedFilterVendor: null });
      }else if(pro === "po_creator"){        
        this.setState({ selectedFilterUser: null });
      }else if(pro === "show_name"){
        this.setState({filterShowName: null})
      }
    };

    goClicked = () => {
      let episode = _.find(this.state?.episodes, {
        episode_id: this.state.selectedEpisode,
      });
      this.props.history.push({
        pathname: "/showdetails",
        state: {
          show_id: this.state.selectedShow?.value || null,
          season_id: this.state.selectedSeason || null,
          episode: episode || -1,
        },
      });
    };

    searchPoCreator=(e=>{
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel(
          "Operation canceled due to new request"
        );
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      if (e.target.value !== null) {
        PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      `/getlookupdata?objectType=user&searchString=${e.target.value}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          userList: response.data?.results,
        });
      }
    );
  }
    })

    searchVendors = (e) => {
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel(
          "Operation canceled due to new request"
        );
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      if (e.target.value !== null) {
        this.setState({ isFetchingVendors: true });
        PostWatchService.getDataWithCancel(
          Constants.postWatchServiceBaseUrl +
            `/getvendorsbyname?vendorName=${e.target.value}&userId=${this.props?.userContext?.user_profile?.user_id}&showId=-1`,
          cancelToken,
          this.props?.userContext?.active_tenant?.tenant_id ,
          1
        ).then(
          (response) => {
            this.setState({
              vendors: response.data,
              isFetchingVendors: false,
            });
          },
          (err) => {
            this.setState({
              isFetchingVendors: false,
            });
          }
        );
      }
    };searchShow = (e) => {
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel(
          "Operation canceled due to new request"
        );
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      if (e.target.value !== null) {
        this.setState({ isFetchingShow: true });
        PostWatchService.getDataWithCancel(
          Constants.postWatchServiceBaseUrl +
            `/getshowdetails?showName=${e.target.value}`,
          cancelToken,
          this.props?.userContext?.active_tenant?.tenant_id ,
          1
        ).then(
          (response) => {
            this.setState({
              shows: response.data,
              isFetchingShow: false,
            });
          },
          (err) => {
            this.setState({
              isFetchingShow: false,
            });
          }
        );
      }
    };

    getPosition = (val) => {
      let isHBOTenant = this.props?.userContext?.active_tenant?.tenant_id;
      switch (val) {
        case "PO#":
          return 0;
        case "Title":
          return 1;
        case "Date":
          return isHBOTenant == 2 ? 2 : 1;
        case "Vendor":
          return isHBOTenant == 2 ? 3 : 2;
        case "Total Cost":
          return isHBOTenant == 2 ? 4 : 3;
        case "Extended Cost":
          return isHBOTenant == 2 ? 5 : 4;
        case "Show":
          return isHBOTenant == 2 ? 6 : 5;
        case "Season":
          return isHBOTenant == 2 ? 7 : 6;
        case "Production Company":
          return isHBOTenant == 2 ? 8 : 7;
        default:
          return 0;
      }
    };

    getColumnName = (val) => {
      switch (val) {
        case "PO#":
          return "po_number";
        case "Title":
          return "title";
        case "Date":
          return "created_at";
        case "Vendor":
          return "vendor_name";
        case "Total Cost":
          return "total_cost";
        case "Extended Cost":
          return "extended_cost";
        case "Show":
          return "show_name";
        case "Season":
          return "season_id";
        case "Production Company":
          return "comp_name";
        default:
          return "PO#";
      }
    };

    sortColumn = (val) => {
      let Position = this.getPosition(val);
      let col_name = this.getColumnName(val);
      let order = null;
      let config = JSON.parse(JSON.stringify(this.state?.config));
      if (this.state.a_z && config?.headings[Position]?.icon != "") {
        if (config?.headings[Position]?.icon == "sort-alpha-up") {
          config?.headings.map((item) => {
            item.icon = "";
          });
          config.headings[Position].icon = "sort-alpha-down";
        } else {
          config?.headings.map((item) => {
            item.icon = "";
          });
          config.headings[Position].icon = "sort-alpha-up";
        }
        order = "desc";
        this.setState({ a_z: false });
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        this.setState({ a_z: true });
        config.headings[Position].icon = "sort-alpha-up";
        order = "asc";
      }
      this.setState({ sortBy: col_name, sortOrder: order, config }, () => {
        this.getPoDetails();
      });
    };

    handleOnSeasonChangeAdmin = (value) => {
      this.setState({
        selectedSeasonAdmin: value,
        selectedShowAdmin: null,
        selectedEpisodeAdmin: null,
        showsListAmdin: null,
        episodesListAmdin: null,
      });
      this.getShowsAdmin(value);
    };
    handleOnShowChangeAdmin = (value) => {
      this.setState({
        selectedShowAdmin: value,
        selectedEpisodeAdmin: null,
        episodesListAmdin: null
      });
      this.getEpisodesAdmin(value);
    };
    handleOnEpisodeChangeAdmin = (value) => {
      this.setState({
        selectedEpisodeAdmin: value,
      });
    };
    goClickedAdmin = () => {
      let episode = _.find(this.state?.episodesListAmdin, {
        episode_id: this.state.selectedEpisodeAdmin,
      });
      let season = _.find(this.state?.showsListAmdin, {
        show_season_id: this.state.selectedShowAdmin,
      });
      this.props.history.push({
        pathname: "/showdetails",
        state: {
          show_id: season?.show_id || null,
          season_id: this.state?.selectedSeasonAdmin || null,
          episode: episode || -1,
        },
      });
    };

    getShowsAdmin = (value) => {
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/getseasonshows?seasonId=" + value,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            showsListAmdin: response.data,
          });
        },
        (err) => {
          console.log("error in get getShowsAdmin");
        }
      );
    };
    getEpisodesAdmin = (value) => {
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl +
          "/getseasonshowepisodes?showSeasonId=" +
          value,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            episodesListAmdin: response.data,
          });
        },
        (err) => {
          console.log("error in get getEpisodesAdmin");
        }
      );
    };
    render() {
      let isLoading = false;
      // this.state.isFetchingUserDetails ||
      // this.state.isFetchingGroupDetails ||
      // this.state.shows ||
      // this.state.se;
      return (
        <>
          <div className="dashboard-content">
            <MDBRow className="heading-row">
              <h4 className="heading-span">Production Browser</h4>
              <MDBRow className="production-filter-row">
                {(!this.props?.userContext?.active_tenant?.permissions
                  ?.isAdmin || this.props?.userContext?.active_tenant?.tenant_id === 2) && (
                  <>
                    <MDBCol md={3} className="production-browser-filters">
                      <SearchSelectField
                      className={"production-filter"}
                      id={"show_name"}
                      detail_selected={this.state?.selectedShow}
                      options={this.state.shows?.map((item) => ({
                        text: item.show_name,
                        value: item.show_id,
                      }))}
                      width={"100%"}
                      multiple={false}
                      searchSelect={true}
                      searchText={this.searchShow}
                      placeholder=" - Type to search show -"
                      noOptionsText="No results"
                      valueSelected={(e, newValue) => {
                        this.handleOnShowChange(newValue?.value)
                        this.setState({ selectedShow: newValue });
                      }}
                      />
                    </MDBCol>
                    <MDBCol md={2} className="production-browser-filters">
                      <SelectField
                        id={"season_name"}
                        size="small"
                        placeHolderText=" - Season -"
                        options={
                          this.state.seasons?.map((item) => ({
                            label: item?.season_name,
                            value: item?.season_id,
                          })) || []
                        }
                        value={this.state?.selectedSeason || ""}
                        onChange={(e) =>
                          this.handleOnSeasonChange(e.target.value)
                        }
                      />
                    </MDBCol>
                    {this.props?.userContext?.active_tenant?.tenant_id !== 2 &&
                      <MDBCol md={2} className="production-browser-filters">
                        <SelectField
                          id={"episode_name"}
                          size="small"
                          placeHolderText=" - Episode -"
                          options={
                            this.state?.episodes?.map((item) => ({
                              label: item?.episode_name,
                              value: item?.episode_id,
                            })) || []
                          }
                          value={this.state?.selectedEpisode || ""}
                          onChange={(e) =>
                            this.handleOnEpisodeChange(e.target.value)
                          }
                        />
                      </MDBCol>
                    }
                    <MDBCol md={1} className="go-btn">
                      <BasicButton
                        text="GO"
                        type="inline"
                        disabled={
                          !this.state.selectedShow || !this.state.selectedSeason
                        }
                        onClick={this.goClicked}
                      />
                    </MDBCol>
                  </>
                )}
                {this.props?.userContext?.active_tenant?.permissions
                  ?.isAdmin && this.props?.userContext?.active_tenant?.tenant_id !== 2 &&(
                  <>
                    <MDBCol md={2} className="production-browser-filters">
                      <SelectField
                        id={"season_name"}
                        size="small"
                        placeHolderText=" - Season -"
                        options={
                          this.state.seasonsList?.map((item) => ({
                            label: item?.season_name,
                            value: item?.season_id,
                          })) || []
                        }
                        value={this.state?.selectedSeasonAdmin || ""}
                        onChange={(e) =>
                          this.handleOnSeasonChangeAdmin(e.target.value)
                        }
                      />
                    </MDBCol>
                    <MDBCol md={3} className="production-browser-filters">
                      <SelectField
                        id={"show_name"}
                        size="small"
                        options={
                          this.state?.showsListAmdin?.map((item) => ({
                            label: item?.show_name,
                            value: item?.show_season_id,
                          })) || []
                        }
                        placeHolderText=" - Show -"
                        value={this.state?.selectedShowAdmin || ""}
                        onChange={(e) =>
                          this.handleOnShowChangeAdmin(e.target.value)
                        }
                      />
                    </MDBCol>
                    {this.props?.userContext?.active_tenant?.tenant_id !== 2 &&
                    <MDBCol md={2} className="production-browser-filters">
                      <SelectField
                        id={"episode_name"}
                        size="small"
                        placeHolderText=" - Episode -"
                        options={
                          this.state?.episodesListAmdin?.map((item) => ({
                            label: item?.episode_name,
                            value: item?.episode_id,
                          })) || []
                        }
                        value={this.state?.selectedEpisodeAdmin || ""}
                        onChange={(e) =>
                          this.handleOnEpisodeChangeAdmin(e.target.value)
                        }
                      />
                    </MDBCol>
    }
                    <MDBCol md={1} className="go-btn">
                      <BasicButton
                        text="GO"
                        type="inline"
                        disabled={
                          !this.state.selectedShowAdmin ||
                          !this.state.selectedSeasonAdmin
                        }
                        onClick={this.goClickedAdmin}
                      />
                    </MDBCol>
                  </>
                )}
              </MDBRow>
              <h4 className="heading-span">Find a PO</h4>
              <MDBRow className="records-limit-checkbox">
                <BasicCheckbox
                  id={"showyear_filter"}
                  label={"Only display records from last 5 years"}
                  value={this.state.filters?.showyear_filter || ""}
                  checked={this.state.filters?.showyear_filter}
                  onChange={(e) =>
                    this.handleFilterChange(
                      "showyear_filter",
                      e.target.checked ? 1 : 0,
                      false,
                      true
                    )
                  }
                />
              </MDBRow>
            </MDBRow>
            <MDBRow>
              <MDBCol md={10} className="mb-2">
                <ul className="chips-list">
                  {Object.keys(this.state.chipFilterObject).map(
                    (key, index) => {
                      if (this.state.chipFilterObject[key].value) {
                        let obj = this.state.chipFilterObject[key];

                        return (
                          <li key={key}>
                            {
                              <Chip
                                onDelete={() =>
                                  this.handleChipDelete(key, {
                                    ...obj,
                                  })
                                }
                                label={
                                  this.state.chipFilterObject[key].label +
                                  ": " +
                                  this.state.chipFilterObject[key].value
                                }
                              />
                            }
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </MDBCol>
              <MDBCol md={2} className="clear-filter-btn">
                {this.state.chipFilterObject && (
                  <BasicButton
                    text="Clear Filters"
                    variant="outlined"
                    type="inline"
                    onClick={this.clearFilterHandler}
                  />
                )}
              </MDBCol>
            </MDBRow>
            <MDBContainer fluid className="dashboard-filters">
              <MDBRow>
                <MDBCol md={12} className="d-flex">
                  <MDBCol md={3} className="d-flex">
                   <SearchSelectField
                      id={"filter_show_name"}
                      detail_selected={this.state?.filterShowName}
                      options={this.state.shows?.map((item) => ({
                        text: item.show_name,
                        value: item.show_id,
                      }))}
                      width={"100%"}
                      multiple={false}
                      searchSelect={true}
                      searchText={this.searchShow}
                      placeholder=" - Type to search show -"
                      noOptionsText="No results"
                      valueSelected={(e, newValue) => {
                        this.handleFilterChange("show_name", newValue.text);
                        this.setState({ filterShowName: newValue });
                      }}
                    />
                  </MDBCol>
                  {this.props?.userContext?.active_tenant?.tenant_id !=2 &&
                  <MDBCol md={3} className="d-flex">
                    <SelectField
                      width={"100%"}
                      id={"filter_comp_name"}
                      options={
                        this.state?.productionCompanyFilterOptions?.map(
                          (item) => ({
                            label: item?.comp_name,
                            value: item?.comp_name,
                          })
                        ) || []
                      }
                      placeHolderText=" - Production Company -"
                      value={this.state?.filters?.prod_company_name || ""}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "prod_company_name",
                          e.target.value
                        )
                      }
                    />
                  </MDBCol>
    }
                      {this.props?.userContext?.active_tenant?.tenant_id ===2 &&

    <MDBCol md={3} className="d-flex">
     <IconTextField
                      placeholder={"Vendor Invoice #"}
                      value={this.state?.filters?.vendor_invoice}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "vendor_invoice",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "vendor_invoice",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
                     <IconTextField
                      placeholder={"Order Number #"}
                      value={this.state?.filters?.order_no}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "order_no",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "order_no",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
                    </MDBCol>
    }
                  <MDBCol md={3} className="d-flex">
                    <SearchSelectField
                      detail_selected={this.state?.selectedFilterVendor}
                      options={this.state.vendors?.map((item) => ({
                        text: item.vendor_name,
                        value: item.vendor_id,
                      }))}
                      width={"100%"}
                      multiple={false}
                      searchSelect={true}
                      searchText={this.searchVendors}
                      placeholder="- Type to search vendors -"
                      noOptionsText="No results"
                      valueSelected={(e, newValue) => {
                        this.handleFilterChange("vendor_name", newValue.text);
                        this.setState({ selectedFilterVendor: newValue });
                      }}
                    />
                  </MDBCol>
                  <MDBCol md={3} className="d-flex">
                    <IconTextField
                      placeholder={"Cost($) below"}
                      value={this.state?.filters?.total_cost_below}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "total_cost_below",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "total_cost_below",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />

                    <IconTextField
                      placeholder={"Cost($) above"}
                      value={this.state?.filters?.total_cost_above}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "total_cost_above",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "total_cost_above",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
                  </MDBCol>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-2">
                <MDBCol md={12} className="d-flex">
                  <MDBCol md={3} className="d-flex">
                    <SelectField
                      id={"season_name"}
                      size="small"
                      options={
                        this.state.seasonsList?.map((item) => ({
                          label: item?.season_name,
                          value: item?.season_name,
                        })) || []
                      }
                      placeHolderText=" - Season -"
                      value={this.state?.filters?.season_name || ""}
                      onChange={(e) =>
                        this.handleFilterChange("season_name", e.target.value)
                      }
                    />
 {this.props?.userContext?.active_tenant?.tenant_id !== 2 &&
                    <IconTextField
                      placeholder={"Episode#"}
                      className={"flex-grow-1"}
                      value={this.state?.filters?.gl_number}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "gl_number",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "gl_number",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
    }
     {(this.props?.userContext?.active_tenant?.tenant_id || this.props?.userContext?.user_profile?.tenant_id) == 2 &&                   
                    <IconTextField
                      placeholder={"POET #"}
                      className="poet_search"
                      value={this.state?.filters?.poet_no}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "poet_no",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "poet_no",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
                    
                    
                
                }
                  </MDBCol>
                  <MDBCol md={3} className="d-flex">
                    <IconTextField
                      placeholder={"PO No. (XX 12345)"}
                      value={this.state?.filters?.po_prefix}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "po_prefix",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "po_prefix",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
                    {this.props?.userContext?.active_tenant?.tenant_id === 2 &&

<SearchSelectField
detail_selected={this.state?.selectedFilterUser}
options={this.state.userList?.map((item) => ({
  text: item.full_name,
  value: item.user_id,
}))}
width={"100%"}
multiple={false}
searchSelect={true}
searchText={this.searchPoCreator}
placeholder="- search PO Creator -"
noOptionsText="No results"
valueSelected={(e, newValue) => {
  this.handleFilterChange("po_creator", newValue.text);
  this.setState({ selectedFilterUser: newValue });
}}
/>

                      // <IconTextField
                      //   placeholder={"PO Creator"}
                      //   value={this.state?.filters?.po_creator}
                      //   onChange={(e) =>
                      //     this.handleFilterChange(
                      //       "po_creator",
                      //       e.target.value === null || e.target.value === ""
                      //         ? null
                      //         : e.target.value,
                      //       false
                      //     )
                      //   }
                      //   onKeyUp={(e) => {
                      //     if (e.keyCode === 13) {
                      //       this.onKeyUpChange(
                      //         "po_creator",
                      //         e.target.value === null || e.target.value === ""
                      //           ? null
                      //           : e.target.value
                      //       );
                      //     }
                      //   }}
                      //   icon={"FaSearch"}
                      // />
                    }
                     {this.props?.userContext?.active_tenant?.tenant_id !=2 &&
                    <IconTextField
                      placeholder={"Detail Number"}
                      value={this.state?.filters?.acc_code}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "acc_code",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "acc_code",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
    }
                  </MDBCol>
                  <MDBCol md={3} className="d-flex">
                    <MDBCol md={6} className="date-width">
                      <DateField
                        value={this.state?.filters?.po_date_after || null}
                        // width={"100%"}
                        onChange={(e) => {
                          this.handleFilterChange(
                            "po_date_after",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }}
                      />
                    </MDBCol>
                    <MDBCol md={6} className="date-width">
                      <DateField
                        value={this.state?.filters?.po_date_before || null}
                        // width={"100%"}
                        onChange={(e) => {
                          this.handleFilterChange(
                            "po_date_before",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }}
                      />
                    </MDBCol>
                  </MDBCol>
                  <MDBCol md={3} className="d-flex">
                    <IconTextField
                      placeholder={"ExtCost($) below"}
                      value={this.state?.filters?.ext_cost_below}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "ext_cost_below",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "ext_cost_below",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />

                    <IconTextField
                      placeholder={"ExtCost($) above"}
                      value={this.state?.filters?.ext_cost_above}
                      onChange={(e) =>
                        this.handleFilterChange(
                          "ext_cost_above",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.onKeyUpChange(
                            "ext_cost_above",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }
                      }}
                      icon={"FaSearch"}
                    />
                  </MDBCol>
                </MDBCol>
              </MDBRow>
           
            </MDBContainer>

            <div>
              {isLoading ? (
                <div className="ContentLoader">
                  <CircularProgress />
                </div>
              ) : (
                <MDBRow
                  className="table-striped dashboard-table"
                  id="dashboard-table"
                >
                  <div id="DashboardTable">
                    <TableComponent
                      list={this.state.tableListSorted || []}
                      config={this.state.config}
                      isLoading={this.state?.isFetchingPODetails || false}
                      stickyHeader={true}
                      sortCallback={this.sortColumn}
                      //options={this.state}
                      //applyFilter={this.applyFilterUsersGridList.bind(this)}
                      //clearFilterHandler={this.clearFilterHandler}
                      //isLoading={false}
                      //sortCallback={this.handleTableColumnClick}
                      //editCallback={(val, item) => this.handleEditUser(val, item)}
                      // tabValue={this.state.tabValue}
                      hyperLinkNavigationCallback={(item, node) =>
                        this.handleUserSelection(item, node)
                      }
                    />

                    <div className="Pagination">
                      <TablePagination
                        component="div"
                        count={this.state?.totalRows}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        page={this.state?.filters?.page_no - 1}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state?.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </MDBRow>
              )}
            </div>
          </div>

          {/* :
                    <div>You do not have permissions to manage users/groups</div>
                } */}
        </>
      );
    }
  }
);
