import React from "react";
import { withRouter } from "react-router-dom";
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import { withUserContext } from "../../contexts/UserContext";
import * as Constants from "../../../src/constants/constants";
import _ from "lodash";
import PostWatchService from "../../../src/services/service";
import "./CreatePO.scss";
import {
  showOption,
  seasonOption,
  episodeOption,
  initialPODetails,
} from "../CreatePO/Config";
import { AppService } from "../../services/AppService";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import axios from "axios";
import NotificationComponent from "../../Common/NotificationComponent/NotificationComponent";
import { CircularProgress } from "@material-ui/core";

class CreatePO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPostJson: JSON.parse(JSON.stringify(initialPODetails)),
      postInitiated: false,
      shows: props?.userContext?.user_profile?.user_shows || null,
      episodes: null,
      seasons: null,
      isFetchingShows: false,
      isFetchingSeason: false,
      isFetchingEpisode: false,
      selectedSeason: props?.season_id || null,
      selectedEpisode: null,
      selectedShow: props?.selectedCopyShow || null,
      poSuccess: false,
      message:'',
      status:'',
      isFetchingCopyPo: false
    };
  }
  handleOnChange = (field, value) => {
    this.setState(
      (prevState) => ({
        userPostJson: {
          ...prevState.userPostJson,
          [field]: value,
        },
      }),
      () => {
        if (this.state?.userPostJson?.show_id) {
          this.getSeason();
        }
      }
    );
  };
  handleOnShowChange = (value) => {
    this.setState({
      selectedShow: value,
      seasons: null,
      selectedSeason: null,
      episodes: null,
      selectedEpisode: null
    });
    this.getSeason(value);
  };
  handleOnSeasonChange = (value) => {
    this.setState({
      selectedSeason: value,
      episodes: null,
      selectedEpisode: null
    });
    this.getEpisodes(value);
  };
  handleOnEpisodeChange = (value) => {
    this.setState({
      selectedEpisode: value,
    });
  };
  componentDidMount() {
    this.shows$ = AppService.getShows().subscribe((data) => {
      if (data) {
        this.setState({ shows: [...data] });
      }
    });
    this.getShows();
    if(this.props?.isCopyPo){
      this.getSeason(this.props?.selectedCopyShow?.value)
    }
    // this.getEpisodes();
  }
  getShows = () => {
    this.setState({ isFetchingShows: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getshows",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        let filterObj = response.data?.map((item) => {
          return { value: item.show_id, label: item.show_name };
        });
        this.setState({
          shows: response.data,
          showsFilterOptions: filterObj,
          isFetchingShows: false,
        });
      },
      (err) => {}
    );
  };
  getSeason = (showId) => {
    this.setState({ isFetchingSeason: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl + "/getshowepisodes?showId=" + showId,
      "",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        console.log(response);
        this.setState({
          seasons: response.data[0]?.show_season,
          isFetchingSeason: false,
        });
      },
      (err) => {}
    );
  };

  getEpisodes = (seasonId) => {
    let season = _.find(this.state.seasons, { season_id: seasonId });
    if (season) {
      this.setState({ episodes: season?.episodes });
    }
  };

  validateBeforeSave = (userDetails) => {
    let canSubmit = true;
    return canSubmit;
  };

  searchShow = (e) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (e.target.value !== null) {
      this.setState({ isFetchingShow: true });
      PostWatchService.getDataWithCancel(
        Constants.postWatchServiceBaseUrl +
          `/getshowdetails?showName=${e.target.value}`,
        cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id ,
        1
      ).then(
        (response) => {
          this.setState({
            shows: response.data,
            isFetchingShow: false,
          });
        },
        (err) => {
          this.setState({
            isFetchingShow: false,
          });
        }
      );
    }
  };

  handleCopyPO =()=>{
    this.setState({isFetchingCopyPo: true});
    let slectedCopySeason = _.find(this.state.seasons, { season_id: this.state.selectedSeason });
    let paylaodJson = {...this.props?.poHeaders};
    paylaodJson["old_po_id"] = this.props?.poHeaders?.po_id
    paylaodJson["project_no_1"] = this.props?.poHeaders?.poet_1?.split('.')[0]
    paylaodJson.po_id = null
    paylaodJson.po_number = null
    paylaodJson.show_season_id = slectedCopySeason?.show_season_id
    paylaodJson["isCopyPo"] = 1
    
    PostWatchService.postDataParams(
     Constants.postWatchServiceBaseUrl +  
     `/postpoheader?userId=` + this.props?.userContext?.user_profile?.user_id,
     paylaodJson,
     this.props?.userContext?.active_tenant?.tenant_id
    ).then(
     (response)=>{
       if(response.data[0]?.Level == "Error"){
        this.setState({poSuccess: true, message: response.data[0]?.Message, status: "error", isFetchingCopyPo: false})
        setTimeout(() => { this.props?.onClose()}, 3000);
       }else if(response.data){
         const copyState = {
           show_id: this.state.selectedShow.value,
           season_id: this.state.selectedSeason,
           episode: -1,
           po_id: response.data[0]?.po_id
         };
        this.setState({poSuccess: true, message:"Po Copied Successfully", status: "success", isFetchingCopyPo: false})
        const queryString = new URLSearchParams(copyState).toString();
        const url = `/showdetails?${queryString}`;
        window.open(url, "_blank");
        this.props?.onClose()
       }
     },(err) => {
      this.setState({
        isFetchingCopyPo: false,
      });
    }
    )
   }

  takeMeThereClick = () => {
    let episode = _.find(this.state?.episodes, {
      episode_id: this.state.selectedEpisode,
    });
    this.props.history.push({
      pathname: "/showdetails",
      state: {
        show_id: this.state.selectedShow.value,
        season_id: this.state.selectedSeason,
        episode: episode || -1,
        createPoFlag: 1,
      },
    });
    this.props.onClose();
  };
  render() {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    let isLoading =
      // this.state.isFetchingEpisode ||
      this.state.isFetchingShows;
    // this.state.isFetchingSeason;
    return (
      <>
      <NotificationComponent
          open={this.state.poSuccess}
          message={this.state.message}
          severity={this.state.status}
          handleResetNotify={() => this.setState({poSuccess : false})}
        />
      {this.props?.isCopyPo ? (
        <MDBCol className="copyContainer">
          <SearchSelectField
              id={"show_name"}
              detail_selected={this.state?.selectedShow}
              options={this.state.shows?.map((item) => ({
                text: item.show_name,
                value: item.show_id,
              }))}
              width={"100%"}
              multiple={false}
              searchSelect={true}
              searchText={this.searchShow}
              placeholder=" - Type to search show -"
              noOptionsText="No results"
              valueSelected={(e, newValue) => {
                this.handleOnShowChange(newValue?.value)
                this.setState({ selectedShow: newValue });
              }}
              />
              <SelectField
                id={"season_name"}
                size="small"
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                options={
                  this.state.seasons?.map((item) => ({
                    label: item?.season_name,
                    value: item?.season_id,
                  })) || []
                }
                value={this.state?.selectedSeason || ""}
                onChange={(e) => this.handleOnSeasonChange(e.target.value)}
              />
              <MDBRow className="justify-content-end">
              <MDBCol className="d-flex justify-content-end mt-1">
              <BasicButton
                className="cursor-pointer me-2"
                variant="contained"
                onClick={this.handleCopyPO}
                text={this.state.isFetchingCopyPo ? (
                    <CircularProgress color="inherit" size={20} />) : ("")}
                icon={"copy"}
              />
              <BasicButton
                className="cursor-pointer"
                variant="outlined"
                onClick={this.props.onClose}
                icon={"times"}
              />
              </MDBCol>
              </MDBRow>
        </MDBCol>
      ) : 
      <div>
        <MDBContainer className="newUserContainer">
          <MDBRow>
            <MDBCol md={8}>
              <BasicLabel text="Show" />
            </MDBCol>
            <MDBCol md={4}>
              <BasicLabel text="Season" />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md={8} className="shows">
            <SearchSelectField
              id={"show_name"}
              detail_selected={this.state?.selectedShow}
              options={this.state.shows?.map((item) => ({
                text: item.show_name,
                value: item.show_id,
              }))}
              width={"100%"}
              multiple={false}
              searchSelect={true}
              searchText={this.searchShow}
              placeholder=" - Type to search show -"
              noOptionsText="No results"
              valueSelected={(e, newValue) => {
                this.handleOnShowChange(newValue?.value)
                this.setState({ selectedShow: newValue });
              }}
              />
            </MDBCol>
            <MDBCol md={4} className="season">
              <SelectField
                id={"season_name"}
                size="small"
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                options={
                  this.state.seasons?.map((item) => ({
                    label: item?.season_name,
                    value: item?.season_id,
                  })) || []
                }
                value={this.state?.selectedSeason || ""}
                onChange={(e) => this.handleOnSeasonChange(e.target.value)}
              />
            </MDBCol>
          </MDBRow>
          {this.props?.userContext?.active_tenant?.tenant_id != 2 &&
          <>
          <MDBRow>
            <MDBCol md={8}>
              <BasicLabel text="Episode" />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md={8} className="episode">
              <SelectField
                id={"episode_name"}
                size="small"
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                options={
                  this.state?.episodes?.map((item) => ({
                    label: item?.episode_name,
                    value: item?.episode_id,
                  })) || []
                }
                value={this.state?.selectedEpisode || ""}
                onChange={(e) => this.handleOnEpisodeChange(e.target.value)}
              />
            </MDBCol>
          </MDBRow>
          </>
              }

          <MDBRow className="btn-row">
            <MDBCol md={7}></MDBCol>
            <MDBCol md={3}>
              <BasicButton
                variant="contained"
                type="inline"
                text={"Create PO"}
                disabled={
                  !(this.state?.selectedShow && this.state?.selectedSeason)
                }
                onClick={this.takeMeThereClick}
              />
            </MDBCol>
            <MDBCol md={2}>
              <BasicButton
                type="inline"
                variant="outlined"
                text={"Cancel"}
                onClick={this.props.onClose}
                disabled={false}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
       }
      </>
    );
  }
}

export default withRouter(withUserContext(CreatePO));
