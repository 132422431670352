import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBContainer,
  MDBNavbarLink,
  MDBDropdownLink,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import React from "react";
import TableComponent from "../../components/SharedComponents/Table";
import * as Constants from "../../constants/constants";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import Popover from "@material-ui/core/Popover";
import { handlePaginationList, tableSortList } from "../../Common/TableHelper";
import TablePagination from "@material-ui/core/TablePagination";
import { withUserContext } from "../../contexts/UserContext";
import _ from "lodash";
import {
  optionalRightsConfig,
  templateList,
  poList,
  initialPODetails,
  newTemplate,
  vendorList,
  initialPoTemplateConfig,
  NewPOHeader,
  NewPOLine,
} from "../PoTemplate/Config";
import "./PoTemplate.scss";
import PostWatchService from "../../../src/services/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
} from "../../../src/Common/Helper";

class PoTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShow: null,
      postInitiated: false,
      templateItems: [],
      selectedTemplate: null,
      editTemplateName: null,
      vendorItems: [],
      poLinesList: null,
      isFetchingPOLines: false,
      showToolTip: false,
      tooltipElement: null,
      tooltipItem: null,
      poHeaders: null,
      NewPOHeader: { ...NewPOHeader },
      setUnits: null,
      isFetchingSetUnits: false,
      accountDetails: null,
      isFetchingAccountDetails: false,
      currencies: null,
      isFetchingCurrencies: false,
      totalCost: null,
      anchorEl: null,
      openPopover: false,
      selectedNoteRow: null,
      selectedNoteMessage: null,
      poRangeData: null,
      activPORangeList: null,
      isLoading: false,
      isTableLoading: false,
      seasons: null,
      selectedSeason: null,
    };
  }

  componentDidMount() {
    if(this.props?.userContext?.active_tenant?.tenant_id!==2){
      this.getSetUnits();
      this.getChartOfAccountDetails();      
    }else{
      if(optionalRightsConfig.dataNodes?.length>=9){
        optionalRightsConfig.headings.splice(0, 3);
        optionalRightsConfig.dataNodes.splice(0, 3);
        optionalRightsConfig.inlineEdits.splice(0, 3);
        optionalRightsConfig.headings[0].width="30%";
        optionalRightsConfig.headings[1].width="10%";
        optionalRightsConfig.headings[2].width="10%";
        optionalRightsConfig.headings[3].width="10%";
        optionalRightsConfig.headings[4].width="10%";
        optionalRightsConfig.headings[5].width="10%";
        optionalRightsConfig.inlineEdits[0].width="28%";
        optionalRightsConfig.inlineEdits[1].width="10%";
        optionalRightsConfig.inlineEdits[2].width="10%";
        optionalRightsConfig.inlineEdits[3].width="10%";
        optionalRightsConfig.inlineEdits[4].width="10%";
        optionalRightsConfig.inlineEdits[5].width="10%";
        
      }
    }
  
  }
  getPOLines = () => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getpotemplates?poTemplateHeaderId=" +
        this.state.selectedTemplate.po_template_header_id,
        this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            poLinesList: response.data,
            isFetchingPOLines: false,
          },
          () => {
            this.calcaluteTotalCost();
          }
        );
      },
      (err) => {
        this.setState({
          isFetchingPOLines: false,
        });
      }
    );
  };

  getSetUnits = () => {
    this.setState({ isFetchingSetUnits: true });
    let filters = {
      unit_code: null,
      description: null,
      page_count: null,
      page_no: null,
    };
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getsetunits",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let setUnitDeails = res.data?.records;
        let option = setUnitDeails.map((item) => {
          return {
            text: item.unit_code + " : " + item.description,
            value: item.setunit_id,
          };
        });
        optionalRightsConfig.headings[1].selectOptions = option;
        this.setState({
          setUnits: setUnitDeails,
          isFetchingSetUnits: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingSetUnits: false });
      });
  };

  getChartOfAccountDetails = () => {
    this.setState({
      isFetchingAccountDetails: true,
    });
    let filters = {
      acc_code: null,
      description: null,
      page_count: null,
      page_no: null,
    };
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getchartofaccounts",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let accountDetails = res.data?.records;
        let option = accountDetails.map((item) => {
          return {
            text: item.acc_code + " : " + item.acc_desc,
            value: item.acc_id,
          };
        });
        optionalRightsConfig.headings[2].selectOptions = option;
        this.setState({
          isFetchingAccountDetails: false,
          accountDetails: res.data?.records,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingAccountDetails: false });
      });
  };

  getCurrencies = () => {
    this.setState({
      isFetchingCurrencies: true,
    });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        "/getshowcurrencies?showId=" +
        this.state.selectedShow,
        '',
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let currencyDetails = res.data;
        let option = currencyDetails.map((item) => {
          return {
            label: item.currency_exchange,
            value: item.currency_exchange,
          };
        });
        if(this.props?.userContext?.active_tenant?.tenant_id!==2){
        optionalRightsConfig.headings[7].selectOptions = option;
        }else{
          optionalRightsConfig.headings[4].selectOptions = option;
        }
        this.setState({
          currencies: currencyDetails,
          isFetchingCurrencies: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingCurrencies: false, isSubmit: false });
      });
  };

  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };
  handleTemplateChange = (value) => {
    if (value == 9999) {
      this.handleOnTemplateNameChange("");
      this.setState({
        poLinesList: [],
        selectedTemplate: { ...newTemplate },
      });
    } else {
      let template = _.find(this.state.templateItems, {
        po_template_header_id: value,
      });
      if (template) {
        this.handleOnTemplateNameChange(template.po_template_name);
        this.setState({ selectedTemplate: template }, () => {
          this.getPOLines();
        });
      }
    }
  };
  handleOnShowChange = (value) => {
    this.setState(
      { selectedShow: value, selectedSeason: null, selectedTemplate: null },
      () => {
        this.getCurrencies();
        this.getSeason();
      }
    );
  };
  handleOnSeasonChange = (value) => {
    this.setState(
      { selectedSeason: value, selectedTemplate: null, editTemplateName: null },
      () => {       
          this.getTemplates();
          this.getGLNumbers();
      }
    );
  };

  handleOnTemplateNameChange = (value) => {
    this.setState({ editTemplateName: value });
  };

  getSeason = () => {
    this.setState({ isLoading: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        "/getshowepisodes?showId=" +
        this.state.selectedShow,
        '',
        this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        console.log(response);
        this.setState({
          seasons: response.data[0]?.show_season,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({ isLoading: false });
      }
    );
  };

  getGLNumbers = () => {
    let glNumbersArr = [];
    let season = _.find(this.state.seasons, {
      season_id: this.state.selectedSeason,
    });
    _.forEach(season.episodes, (episode) => {
      glNumbersArr.push({
        label: episode.gl_number,
        value: episode.gl_number,
      });
    });
    optionalRightsConfig.headings[0].selectOptions = glNumbersArr;
  };

  saveTemplate = (fromDeleteTemplate = false) => {
    if (this.state.editTemplateName?.length > 0) {
      let data = {
        po_template_header_id:
          this.state.selectedTemplate.po_template_header_id === 9999
            ? null
            : this.state.selectedTemplate.po_template_header_id,
        po_template_name: this.state.editTemplateName || "",
        is_deleted: this.state.selectedTemplate?.is_deleted || 0,
        user_id: this.props?.userContext?.user_profile?.user_id,
        show_id: this.state.selectedShow,
        season_id: this.state.selectedSeason,
      };
      this.setState({ isLoading: true });
      PostWatchService.postData(
        Constants.postWatchServiceBaseUrl +
          `/posttemplatedetails?userId=${this.props?.userContext?.user_profile?.user_id}`,
        data,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (!response.data.error) {
            this.getTemplates();
            /* If saveTemplate call is from delete template then clear selected template
          and check for "true", since from season change fromDeleteTemplate will be html element which will pass the if condition */
            if (fromDeleteTemplate === true) {
              this.setState({ selectedTemplate: null });
            } else {
              let selectedTemplate = { ...this.state.selectedTemplate };
              selectedTemplate.po_template_header_id =
                response.data[0]?.po_template_header_id;
              this.setState({ selectedTemplate, isLoading: false });
            }
          } else {
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          this.setState({ isLoading: false });
          console.log("Post polineitem error: " + err);
        }
      );
    }
  };
  deleteTemplate = () => {
    let selectedTemplate = { ...this.state.selectedTemplate };
    selectedTemplate.is_deleted = 1;
    this.setState({ selectedTemplate }, () => {
      this.saveTemplate(true);
      this.handleOnTemplateNameChange("");
    });
  };

  getTemplates = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const copyShowId =parseInt(queryParams.get('show_id'), 10) || this.state.selectedShow
    this.setState({ isLoading: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        "/getuserpotemplates?showId=" +
        copyShowId +
        "&seasonId=" +
        this.state.selectedSeason +
        "&userId=" +
        this.props?.userContext?.user_profile?.user_id,
      "",
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let data = res.data;
        // Set teamplate list only if template is created/edited for current show and season in show details page, so that its available in Add from template dropdown,
        // for others no need since they will be retrived on page reload
        if (
          this.props?.userContext?.currentShowSelected ===
            this.state?.selectedShow &&
          this.props?.userContext?.currentSeasonSelected ===
            this.state?.selectedSeason
        ) {
          this.props.userContext.setTemplateItems(data);
        }

        data = [...data, { ...newTemplate }];
        this.setState({
          templateItems: data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false });
      });
  };

  calcaluteTotalCost = () => {
    let totalCost = this.state.poLinesList?.reduce((accumulator, item) => {
      return accumulator + item.total_cost;
    }, 0);
    totalCost = Number.parseFloat(totalCost).toFixed(2);
    totalCost = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(totalCost);

    this.setState({ totalCost });
  };

  saveInlineEdits = (data, index) => {
    if (true || this.validateBeforeSave(data[index])) {
      // Since these are searchtext they will return item with value and label from which we need to send only id
      // If dropdowns are not changed then take direct number, if dropdown are changed then we get objects so take ?.value
      data[index].setunit_id = data[index]?.setunit_id?.value
        ? data[index]?.setunit_id?.value
        : data[index]?.setunit_id;
      data[index].chartofaccount_id = data[index]?.chartofaccount_id?.value
        ? data[index]?.chartofaccount_id?.value
        : data[index]?.chartofaccount_id;
      data[
        index
      ].po_template_header_id = this.state.selectedTemplate.po_template_header_id;
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      let currencyItem = _.find(this.state.currencies, {
        currency_exchange: data[index].currency_exchange,
      });

      if (currencyItem) {
        data[index].adj_currency_id = currencyItem.currency_id;
        data[index].adj_curr_exch_rate = currencyItem.exchange_rate;
      }
      this.postData(data[index]);
    }
  };

  postData = (data) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
        `/postpotemplate?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      data,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (!response.data.error) {
          this.setState({
            isFetchingPOLines: false,
            postInitiated: false,
          });
          this.getPOLines(this.props?.poOrder?.po_id);
        } else {
          this.setState({ isFetchingPOLines: false });
        }
      },
      (err) => {
        this.setState({ isFetchingPOLines: false });
        console.log("Post polineitem error: " + err);
      }
    );
  };
  getPoLines = () => {
    this.setState({ isLoading: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        "/getpotemplates?showId=" +
        this.state.selectedShow +
        "&userId=" +
        this.props?.userContext?.user_profile?.user_id,
      "",
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        this.setState({
          poLinesList: res.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false });
      });
  };

  handleInlineEdits = (id, dataItem) => {
    let poLinesList = this.state.poLinesList;
    this.setState({
      poLinesList: poLinesList.map(
        (item) => {
          if (item.po_template_id === id) {
            item["editing"] = true;
            item["setunit_id"] = {
              value: item["setunit_id"],
              text: item["set_unit"],
            };
            item["chartofaccount_id"] = {
              value: item["chartofaccount_id"],
              text: item["acc_detail"],
            };
          }
          return item;
        },
        () => {
          console.log("handleInlineEdit", this.state.poLinesList);
        }
      ),
    });
  };

  handleSyncPopupClick = () => {};
  handleClickEPSModal = () => {};

  validateBeforeSave = (newDataItem) => {
    // Check for required fields
    let canSubmit =
      newDataItem?.gl_number &&
      newDataItem?.setunit_id &&
      newDataItem?.chartofaccount_id &&
      newDataItem?.unit_cost &&
      newDataItem?.quantity &&
      newDataItem?.adj_currency_id;

    return canSubmit;
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (true || this.validateBeforeSave(newDataItem)) {
      newDataItem.po_template_id = null;
      newDataItem.po_template_header_id = this.state.selectedTemplate.po_template_header_id;
      newDataItem.chartofaccount_id = newDataItem?.chartofaccount_id?.value;
      newDataItem.setunit_id = newDataItem?.setunit_id?.value;
      newDataItem.subtotal = newDataItem.quantity * newDataItem.unit_cost;

      let currency = _.find(this.state.currencies, {
        currency_id: newDataItem?.adj_currency_id,
      });

      if (currency?.exchange_rate) {
        newDataItem.total_cost = newDataItem.subtotal * currency.exchange_rate;
        newDataItem.total_cost = Number.parseFloat(
          newDataItem.total_cost
        ).toFixed(2);
      }
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      let currencyItem = _.find(this.state.currencies, {
        currency_exchange: newDataItem.currency_exchange,
      });
      if (currencyItem) {
        newDataItem.adj_currency_id = currencyItem.currency_id;
        newDataItem.adj_curr_exch_rate = currencyItem.exchange_rate;
      }

      optionalRightsConfig.isClearFields = true;
      this.postData(newDataItem);
    } else {
      // This will not clear already entered data
      optionalRightsConfig.isClearFields = false;
    }
  };

  handleDelCallBack = (id, dataItem) => {
    let data = dataItem;
    data.is_deleted = 1;
    this.postData(data);
  };

  handleCommentBox = (e, data) => {
    let poLine = _.find(this.state?.poLinesList, {
      po_template_id: data.po_template_id,
    });
    this.setState({
      anchorEl: e.currentTarget,
      openPopover: true,
      selectedNoteRow: poLine,
      selectedNoteMessage: data.notes || null,
    });
  };
  handleOnNoteChange = (value) => {
    this.setState({ selectedNoteMessage: value });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, openPopover: false });
  };
  saveNotes = () => {
    let noteRow = { ...this.state.selectedNoteRow };
    noteRow.notes = this.state.selectedNoteMessage;
    this.postData(noteRow);
    this.handleClose();
  };

  render() {
    let showToolTip = Boolean(this.state.showToolTip);
    let tableLoading =
      this.state.isFetchingSetUnits ||
      this.state.isFetchingAccountDetails ||
      this.state.isFetchingCurrencies;
    return (
      <MDBContainer className="poTemplateContainer">
        <MDBRow>
          <MDBCol md={2}>
            <BasicLabel text="Select a Show" />
          </MDBCol>
          <MDBCol md={2}>
            <BasicLabel text="Select a Season" />
          </MDBCol>
          <MDBCol md={2}>
            <BasicLabel text="Select Template" />
          </MDBCol>
          <MDBCol md={6}></MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={2}>
            <SelectField
              id={"show_name"}
              options={
                this.props?.userContext?.user_profile?.user_shows?.map(
                  (item) => ({
                    label: item?.show_name,
                    value: item?.show_id,
                  })
                ) || []
              }
              placeHolderText=" - Show -"
              value={this.state?.selectedShow || ""}
              onChange={(e) => this.handleOnShowChange(e.target.value)}
            />
          </MDBCol>
          <MDBCol md={2}>
            <SelectField
              id={"show_season"}
              options={
                this.state?.seasons?.map((item) => ({
                  label: item?.season_name,
                  value: item?.season_id,
                })) || []
              }
              placeHolderText=" - Season -"
              value={this.state?.selectedSeason || ""}
              onChange={(e) => this.handleOnSeasonChange(e.target.value)}
            />
          </MDBCol>
          <MDBCol md={2}>
            <SelectField
              id={"template_id"}
              size="small"
              options={
                this.state.templateItems?.map((item) => ({
                  label: item?.po_template_name,
                  value: item?.po_template_header_id,
                })) || []
              }
              value={this.state.selectedTemplate?.po_template_header_id || ""}
              onChange={(e) => this.handleTemplateChange(e.target.value)}
            />
          </MDBCol>
          <MDBCol md={2} className="pe-0 save-template">
            <MDBNavbarItem>
              {this.state?.selectedTemplate && (
                <MDBNavbarLink
                  className="save-template"
                  onClick={this.saveTemplate}
                >
                  Save Template
                </MDBNavbarLink>
              )}
            </MDBNavbarItem>
          </MDBCol>
          <MDBCol md={2} className="ps-0 delete-template">
            <MDBNavbarItem>
              {this.state?.selectedTemplate &&
                this.state?.selectedTemplate?.po_template_header_id !==
                  9999 && (
                  <MDBNavbarLink
                    className="delete-template"
                    onClick={this.deleteTemplate}
                  >
                    Delete Template
                  </MDBNavbarLink>
                )}
            </MDBNavbarItem>
          </MDBCol>
          <MDBCol md={2}>
            {this.state.isLoading && (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={12}>
            <hr />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {this.state?.selectedTemplate && (
            <MDBCol md={2}>
              <BasicLabel text="Template Name" />
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          {this.state?.selectedTemplate && (
            <MDBCol md={2}>
              <BasicTextField
                value={this.state?.editTemplateName || ""}
                limit={"200"}
                onChange={(e) =>
                  this.handleOnTemplateNameChange(e.target.value)
                }
              />
            </MDBCol>
          )}
          {/* <MDBCol md={4}>
            <SelectField
              id={"vendor_id"}
              size="small"
              isMandatory={true}
              showMandatory={this.state.postInitiated}
              options={this.state.vendorItems || []}
              value={this.state?.userPostJson?.vendor_id || ""}
              onChange={(e) => this.handleOnChange("vendor_id", e.target.value)}
            />
          </MDBCol> */}
        </MDBRow>

        <MDBRow
          className="table-striped potemplate-table"
          id="potemplate-table"
        >
          {this.state?.selectedTemplate?.po_template_header_id &&
            this.state?.selectedTemplate?.po_template_header_id !== 9999 && (
              <MDBCol md={12}>
                {tableLoading ? (
                  <div className="ContentLoader">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <TableComponent
                      list={this.state?.poLinesList || []}
                      config={optionalRightsConfig}
                      isLoading={this.state.isFetchingPOLines}
                      tableMode={"light"}
                      editCallback={this.handleInlineEdits.bind(this)}
                      saveCallback={this.saveInlineEdits.bind(this)}
                      handleAddNewItem={this.handleAddNewItem.bind(this)}
                      addItemToList={!this.state.poHeaders?.is_void}
                      handleOnHoverEnter={this.handleOnHoverEnter}
                      handleOnHoverLeave={this.handleOnHoverLeave}
                      handleDelCallBack={this.handleDelCallBack}
                      showMandatory={this.state.postInitiated}
                      handleCommentBox={this.handleCommentBox}
                      currencies={this.state.currencies}
                      showNotesTooltip={true}
                      autoCalculate={true}
                    />
                    <div class="po-table-total-container  c-black">
                      {this.state.totalCost !== null && (
                        <>
                          <div>Total Amount</div>
                          <div>{this.state.totalCost}</div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </MDBCol>
            )}
        </MDBRow>
        <MDBRow>
          <MDBCol md={12}>
            <Popover
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id={"popover"}
              open={this.state.openPopover}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
            >
              <MDBRow>
                <MDBCol md={12}>
                  <BasicTextArea
                    width="300px"
                    className="polines-notes"
                    rows={3}
                    value={this.state.selectedNoteMessage || ""}
                    onChange={(e) => this.handleOnNoteChange(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={12} className="d-flex justify-content-end">
                  <BasicButton
                    text={"Save"}
                    className="m-1"
                    onClick={() => this.saveNotes()}
                  />
                  <BasicButton
                    variant="outlined"
                    type="inline"
                    className="m-1"
                    text={"Close"}
                    onClick={() => this.handleClose()}
                  />
                </MDBCol>
              </MDBRow>
            </Popover>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={10}></MDBCol>
          <MDBCol md={1}></MDBCol>
          <MDBCol md={1} className="d-flex justify-content-end mb-2 mt-2">
            <BasicButton
              type="inline"
              variant="outlined"
              text={"Exit"}
              onClick={this.props.onClose}
              disabled={false}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(PoTemplate);
